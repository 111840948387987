import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import ".././myclaims.scss";
import { toast } from "react-toastify";

import Environment from "../../../utils/Environment";
import EthPrice from "../../../hooks/ethPrice";
import axios from "axios";
import AllowClaimingHook from "../../../hooks/dataSenders/claiming";
import { set } from "lodash";
import useWeb3 from "../../../hooks/useWeb3";
import { Api_Url } from "../../../utils/ApiUrl";
import Claimable from "../../../hooks/dataFetchers/claimable";
import AllowClaimHook from "../../../hooks/dataSenders/claim";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Myclaims from "../Myclaims";

import Timer from "../../../hooks/dataFetchers/timer";

import Loader from "../../../hooks/loader";
import BuyAgain1 from "../../../hooks/dataFetchers/buyAgain1";
import DiscountedPrice from "../../../hooks/discountedPrice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GetGemsStaked from "../../../hooks/dataFetchers/getGemsStakedAmount";

function TableRow({
  item,
  index,
  usdPrice,
  setLoarder,
  loaderr,
  setRend,
  rend,
  tomiPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,
  openWalletModal,
  setOpenWalletModal,
}) {
  const history = useHistory();

  const { ethPrice } = EthPrice();

  const { buyAgain1 } = BuyAgain1();
  const { timer } = Timer();
  const { discountedPrice } = DiscountedPrice();
  const { getGemsStaked } = GetGemsStaked();

  const web3 = useWeb3();
  const [allowedStatus, setAllowedStatus] = useState();
  const project = localStorage.getItem("project");

  const user = JSON.parse(localStorage.getItem("user"));
  let tok = localStorage.getItem("accessToken");
  const { allowClaimingHook } = AllowClaimingHook();
  const { CheckClaimableHook } = Claimable();
  const { allowClaimHook } = AllowClaimHook();

  const [showStaking, setShowStaking] = useState(false);

  const handleCloseStaking = () => setShowStaking(false);
  const handleShowStaking = () => setShowStaking(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const { account } = useWeb3React();
  const [claimStatus, setClaimStatus] = useState();
  const [statusState, setStatusState] = useState();
  const [ethValue, setEthValue] = useState(0.0);
  const [usdtVlaue, setUsdtValue] = useState(0.0);
  const [tomiVlaue, setTomiValue] = useState(0.0);
  const [wbtcValue, setWbtcValue] = useState(0.0);
  const [usdcValue, setUsdcValue] = useState(0.0);

  const [pepeValue, setPepeValue] = useState(0.0);
  const [linkValue, setLinkValue] = useState(0.0);
  const [uniValue, setUniValue] = useState(0.0);
  const [dopValue, setDopValue] = useState(0.0);
  const [gemsValue, setGemsValue] = useState(0.0);

  const [mainTag, setMainTag] = useState("ETH");
  const [inputFeild, setInputField] = useState(0);
  const [ethPricevalue, setEthPrice] = useState(0);
  const [inputFeild1, setInputField1] = useState(0);
  const [inputFeild3, setInputField3] = useState(0);
  const [inputFeild2, setInputField2] = useState(0);
  const [roundPrice, setRountPrice] = useState(0);
  const [roundPriceEth, setRountPriceEth] = useState(0);
  const [roundPriceUsd, setRountPriceUsdt] = useState(0);
  const [roundPriceTomi, setRountPriceTomi] = useState(0);
  const [roundPriceWbtc, setRountPriceWbtc] = useState(0);
  const [roundPriceUsdc, setRountPriceUsdc] = useState(0);

  const [roundPricePepe, setRountPricePepe] = useState(0);
  const [roundPriceLink, setRountPriceLink] = useState(0);
  const [roundPriceUni, setRountPriceUni] = useState(0);
  const [roundPriceDop, setRountPriceDop] = useState(0);
  const [roundPriceGems, setRountPriceGems] = useState(0);

  const [check, setCheck] = useState(false);
  const [inputFeildMainTomi, setInputFeildMainTomi] = useState(0);
  const [inputFeildTomi, setInputFeildTomi] = useState(0);

  const [inputFeildMainWbtc, setInputFeildMainWbtc] = useState(0);
  const [inputFeildWbtc, setInputFeildWbtc] = useState(0);

  const [inputFieldMainUsdc, setInputFieldMainUsdc] = useState(0);
  const [inputFieldUsdc, setInputFieldUsdc] = useState(0);

  const [inputFieldMainPepe, setInputFieldMainPepe] = useState(0);
  const [inputFieldPepe, setInputFieldPepe] = useState(0);

  const [inputFieldMainLink, setInputFieldMainLink] = useState(0);
  const [inputFieldLink, setInputFieldLink] = useState(0);

  const [inputFieldMainUni, setInputFieldMainUni] = useState(0);
  const [inputFieldUni, setInputFieldUni] = useState(0);

  const [inputFieldMainDop, setInputFieldMainDop] = useState(0);
  const [inputFieldDop, setInputFieldDop] = useState(0);

  const [inputFieldMainGems, setInputFieldMainGems] = useState(0);
  const [inputFieldGems, setInputFieldGems] = useState(0);

  const [currentRound, setCurrentRound] = useState(null);
  const [stakeIndex, setStakeIndex] = useState([]);
  const [stakedAmount, setStakedAmount] = useState(0);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => {
    setShow4(false);
    setInputField(0);
    setInputField1(0);
    setInputField2(0);
    setInputField3(0);
    setInputFeildMainTomi(0);
    setInputFeildTomi(0);

    setInputFieldMainDop(0);
    setInputFieldDop(0);

    setInputFieldMainPepe(0);
    setInputFieldPepe(0);

    setInputFieldMainLink(0);
    setInputFieldLink(0);

    setInputFieldMainUni(0);
    setInputFieldUni(0);

    setInputFieldMainGems(0);
    setInputFieldGems(0);
  };
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => {
    setShow5(false);
    window.location.reload();
  };
  const handleShow5 = () => setShow5(true);

  const Claiming = async (bool, round, decesion) => {
    let res = await allowClaimingHook(bool, round, decesion);
    setClaimStatus(res);
  };
  const claim = async () => {
    // Environment variables
    const Tokens = {
      eth: ethValue,
      busd: usdtVlaue,
      tomi: tomiVlaue,
      wbtc: wbtcValue,
      usdc: usdcValue,
      pepe: pepeValue,
      link: linkValue,
      uni: uniValue,
      dop: dopValue,
      gems: gemsValue,
    };

    // Create an array to storse the Tokens variables with values greater than zero
    const tokensAddressArray = [];

    // Iterate through the Tokens object and push variables with values greater than zero
    for (const key in Tokens) {
      if (Tokens[key] > 0) {
        tokensAddressArray.push(Environment[key]);
      }
    }

    // console.log("eeeeeeeee", tokensAddressArray);
    if (!account) {
      toast.info("Connect Your Wallet");
      return;
    }
    if (account.toLowerCase() !== user?.walletAddress?.toLowerCase()) {
      toast.error("Wrong Wallet For Claim");
      return;
    }
    try {
      setLoarder(true);

      let res = await allowClaimHook(
        account,
        item[0]?._id?.round,
        tokensAddressArray
      );

      // setSelected[]
      setRend(!rend);
      setShow(false);
      setShow1(true);
      setLoarder(false);
    } catch (error) {
      toast.error("Selected Claim Failed");
      setLoarder(false);
    }
  };

  const ClaimedGraph = async () => {
    let Round = item[0]?._id?.round;
    // console.log('rrrrrrrrrrrr', item[0]?._id?.round)
    var data = JSON.stringify({
      query: `query MyQuery {
  fundsClaimeds(where: {by: "${user?.walletAddress}", round: ${Round}}) {
    round
    by
    id
  }
  investedWithClaimAmounts(where: {by: "${user?.walletAddress}", round: ${Round}}) {
    
    blockTimestamp
    dopPurchased
    by
    round
    id
  }
}`,
      variables: {},
    });

    var dataGEMS = JSON.stringify({
      query: `query MyQuery {
  fundsClaimeds(where: {by: "${user?.walletAddress}", round: ${Round}}) {
    round
    by
    id
  }
  purchasedWithClaimAmounts(where: {by: "${user?.walletAddress}", round: ${Round}}) {
    
    blockTimestamp
    tokenPurchased
    by
    round
    id
  }
}`,
      variables: {},
    });

    var config = {
      method: "post",
      url:
        project == "INCENTIV"
          ? Environment?.IncentivGraph
          : parseInt(Round) < 7 && project == "DOP"
          ? Environment?.oldGraph
          : project == "DOP"
          ? Environment?.graph
          : project == "LEIA"
          ? Environment?.LEIAGraph
          : project == "OLYMPUS"
          ? Environment?.OlympusGraph
          : Environment?.GemsGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: project == "DOP" ? data : dataGEMS,
    };
    axios(config).then(async (response) => {
      setStatusState(response.data.data);
    });
  };

  const fetchStakingData = async () => {
    var data = JSON.stringify({
      query: `query MyQuery {
        stakeds(where: {isClaimed: false, staker: "${account}"}) {
          stakerStakeIndex
          transactionHash
          stakeEndTime
          staker
          isClaimed
          id
          blockTimestamp
          blockNumber
          amount
        }
      }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: Environment?.GemsStakingGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        const resData = response.data.data.stakeds;
        if (resData.length > 0) {
          let a = resData.map((e) => {
            return e.stakerStakeIndex;
          });
          setStakeIndex(a);
        }
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  };

  const getGemsStakedAmount = async () => {
    const staked = await getGemsStaked(user?.walletAddress);
    if (staked) {
      setStakedAmount(staked);
    }
  };

  useEffect(() => {
    // console.log("rewwwward", item[0]?._id?.round, claimStatus);
    const funn = async () => {
      if (project == "DOP") {
        if (parseInt(item[0]?._id?.round) < 7) {
          try {
            let res = await CheckClaimableHook(
              user?.walletAddress,
              item[0]?._id?.round,
              Environment.busd
            );
            let dumObj = res;
            if (!res?.amountTomi) {
              dumObj.amountTomi = 0;
            }
            if (!res?.amountUsdc) {
              dumObj.amountUsdc = 0;
            }
            if (!res?.amountWbtc) {
              dumObj.amountWbtc = 0;
            }
            setAllowedStatus(dumObj);
            // console.log("rewwwward", res);
          } catch (error) {
            // console.log("rewwwward", error, item[0]?._id?.round);
          }
        } else {
          let busd = await CheckClaimableHook(
            user?.walletAddress,
            item[0]?._id?.round,
            Environment.busd
          );
          let tomi = await CheckClaimableHook(
            user?.walletAddress,
            item[0]?._id?.round,
            Environment.tomi
          );
          let eth = await CheckClaimableHook(
            user?.walletAddress,
            item[0]?._id?.round,
            Environment.eth
          );

          let wbtc = await CheckClaimableHook(
            user?.walletAddress,
            item[0]?._id?.round,
            Environment.wbtc
          );
          let usdc = await CheckClaimableHook(
            user?.walletAddress,
            item[0]?._id?.round,
            Environment.usdc
          );
          setAllowedStatus({
            amountEth: eth,
            amountUsd: busd,
            amountTomi: tomi,
            amountWbtc: wbtc,
            amountUsdc: usdc,
          });
        }
      } else {
        let busd = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.busd
        );

        let eth = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.eth
        );

        let wbtc = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.wbtc
        );

        let tomi = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.tomi
        );

        let usdc = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.usdc
        );

        let pepe = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.pepe
        );
        let link = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.link
        );
        let uni = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.uni
        );

        let dop = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.dop
        );

        let gems = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.gems
        );

        setAllowedStatus({
          amountEth: eth,
          amountUsd: busd,
          amountWbtc: wbtc,
          amountTomi: tomi,
          amountUsdc: usdc,
          amountPepe: pepe,
          amountLink: link,
          amountUni: uni,
          amountDop: dop,
          amountGems: gems,
        });
      }
    };
    funn();
    Claiming(true, item[0]?._id?.round, null);
    if (user?.walletAddress) {
      ClaimedGraph();
      fetchStakingData();
      getGemsStakedAmount();
    }
  }, [rend, user?.walletAddress]);

  let ethrounValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 100000) / 100000;
    const ethAmount = parseFloat(truncatedValue || 0)?.toFixed(5);
    return ethAmount;
  };
  let usdtrounValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const ethAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return ethAmount;
  };
  let wbtcroundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000000) / 10000000;
    const wbtcAmount = parseFloat(truncatedValue || 0)?.toFixed(6);
    return wbtcAmount;
  };

  let tomiroundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 100000) / 100000;
    const tomiAmount = parseFloat(truncatedValue || 0)?.toFixed(5);
    return tomiAmount;
  };

  let usdcroundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const usdcAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return usdcAmount;
  };

  let peperoundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const pepeAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return pepeAmount;
  };
  let linkroundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const linkAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return linkAmount;
  };
  let uniroundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const uniAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return uniAmount;
  };

  let doproundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const dopAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return dopAmount;
  };

  let gemsroundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const gemsAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return gemsAmount;
  };

  const dataValue = () => {
    if (item[0]?._id?.tokenSelected) {
      if (item[0]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "TOMI") {
        setTomiValue(tomiroundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "GEMS") {
        setGemsValue(gemsroundValue(item[0]?.amount));
      }

      if (item[1]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "TOMI") {
        setTomiValue(tomiroundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "GEMS") {
        setGemsValue(gemsroundValue(item[1]?.amount));
      }

      if (item[2]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "TOMI") {
        setTomiValue(tomiroundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "GEMS") {
        setGemsValue(gemsroundValue(item[2]?.amount));
      }

      if (item[3]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "TOMI") {
        setTomiValue(tomiroundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "GEMS") {
        setGemsValue(gemsroundValue(item[3]?.amount));
      }

      if (item[4]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "TOMI") {
        setTomiValue(tomiroundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "GEMS") {
        setGemsValue(gemsroundValue(item[4]?.amount));
      }

      if (item[5]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "TOMI") {
        setTomiValue(tomiroundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "GEMS") {
        setGemsValue(gemsroundValue(item[5]?.amount));
      }

      if (item[6]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "TOMI") {
        setTomiValue(tomiroundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "GEMS") {
        setGemsValue(gemsroundValue(item[6]?.amount));
      }

      if (item[7]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "TOMI") {
        setTomiValue(tomiroundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "GEMS") {
        setGemsValue(gemsroundValue(item[7]?.amount));
      }

      if (item[8]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[8]?.amount));
      } else if (item[8]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[8]?.amount));
      } else if (item[8]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[8]?.amount));
      } else if (item[8]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[8]?.amount));
      } else if (item[8]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[8]?.amount));
      } else if (item[8]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[8]?.amount));
      } else if (item[8]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[8]?.amount));
      } else if (item[8]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[8]?.amount));
      } else if (item[8]?._id?.tokenSelected === "TOMI") {
        setTomiValue(tomiroundValue(item[8]?.amount));
      } else if (item[8]?._id?.tokenSelected === "GEMS") {
        setGemsValue(gemsroundValue(item[8]?.amount));
      }

      if (item[9]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[9]?.amount));
      } else if (item[9]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[9]?.amount));
      } else if (item[9]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[9]?.amount));
      } else if (item[9]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[9]?.amount));
      } else if (item[9]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[9]?.amount));
      } else if (item[9]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[9]?.amount));
      } else if (item[9]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[9]?.amount));
      } else if (item[9]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[9]?.amount));
      } else if (item[9]?._id?.tokenSelected === "TOMI") {
        setTomiValue(tomiroundValue(item[9]?.amount));
      } else if (item[9]?._id?.tokenSelected === "GEMS") {
        setGemsValue(gemsroundValue(item[9]?.amount));
      }
    }
  };
  const firstTimeData11 = async () => {
    try {
      let a = await ethPrice();
      if (a) {
        setEthPrice(a);
      }
    } catch (err) {
      // console.log(err.message);
    }
  };
  const firstTimeData112 = async () => {
    // if (item[0]?._id?.round > 6) {
    try {
      let a = await timer(item[0]?._id?.round);
      if (a) {
        const price = parseInt(a?.price) / 10 ** 18;
        setRountPrice(price);
      }
    } catch (err) {
      console.log(err.message, "noor");
    }
    // } else {
    //   try {
    //     let a = await timerNFT(item[0]?._id?.round);
    //     if (a) {
    //       setRountPrice(a?.price / 10 ** 18);
    //     }
    //   } catch (err) {
    //     console.log(err.message, "noor");
    //   }
    // }
  };
  useEffect(() => {
    dataValue();
    firstTimeData11();
    firstTimeData112();
  }, [item]);

  const inputField1Handler = (e) => {
    // if (item[0]?._id?.round > 6) {
    let value = e.target.value;
    let tokenPrice = 0;
    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceEth - roundPriceEth * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceEth - roundPriceEth * 0.07;
    } else {
      tokenPrice = roundPriceEth;
    }
    if (ethValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((ethPricevalue * value) / tokenPrice).toFixed(
          6
        );
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setInputField(value);
      } else {
        setInputField1(0);
        setInputField("");
      }
    } else {
      setCheck(true);
      setInputField1(0);
      setInputField("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1Handler1 = (e) => {
    // if (item[0]?._id?.round > 6) {
    let value = e.target.value;
    let tokenPrice = 0;
    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUsd - roundPriceUsd * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUsd - roundPriceUsd * 0.07;
    } else {
      tokenPrice = roundPriceUsd;
    }
    if (usdtVlaue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat(value / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputField3(parseFloat(amount1)?.toFixed(2));
        setInputField2(value);
      } else {
        setInputField3(0);
        setInputField2("");
      }
    } else {
      setCheck(true);
      setInputField3(0);
      setInputField2("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1Handler3 = (e) => {
    let value = e.target.value;
    if (wbtcValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((btcPrice * value) / roundPriceWbtc).toFixed(6);
        let amount1 = "";
        if (roundPriceWbtc > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFeildWbtc(parseFloat(amount1)?.toFixed(2));
        setInputFeildMainWbtc(value);
      } else {
        setInputFeildWbtc(0);
        setInputFeildMainWbtc("");
      }
    } else {
      setCheck(true);
      setInputFeildWbtc(0);
      setInputFeildMainWbtc("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1Handler4 = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUsdc - roundPriceUsdc * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUsdc - roundPriceUsdc * 0.07;
    } else {
      tokenPrice = roundPriceUsdc;
    }
    if (usdcValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat(value / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldUsdc(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainUsdc(value);
      } else {
        setInputFieldUsdc(0);
        setInputFieldMainUsdc("");
      }
    } else {
      setCheck(true);
      setInputFieldUsdc(0);
      setInputFieldMainUsdc("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1HandlerPepe = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.07;
    } else {
      tokenPrice = roundPricePepe;
    }
    if (pepeValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((pepePrice * value) / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldPepe(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainPepe(value);
      } else {
        setInputFieldPepe(0);
        setInputFieldMainPepe("");
      }
    } else {
      setCheck(true);
      setInputFieldPepe(0);
      setInputFieldMainPepe("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1HandlerLink = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceLink - roundPriceLink * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceLink - roundPriceLink * 0.07;
    } else {
      tokenPrice = roundPriceLink;
    }
    if (linkValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((linkPrice * value) / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldLink(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainLink(value);
      } else {
        setInputFieldLink(0);
        setInputFieldMainLink("");
      }
    } else {
      setCheck(true);
      setInputFieldLink(0);
      setInputFieldMainLink("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1HandlerUni = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUni - roundPriceUni * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUni - roundPriceUni * 0.07;
    } else {
      tokenPrice = roundPriceUni;
    }
    if (uniValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((uniPrice * value) / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldUni(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainUni(value);
      } else {
        setInputFieldUni(0);
        setInputFieldMainUni("");
      }
    } else {
      setCheck(true);
      setInputFieldUni(0);
      setInputFieldMainUni("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1HandlerDop = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceDop - roundPriceDop * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceDop - roundPriceDop * 0.07;
    } else {
      tokenPrice = roundPriceDop;
    }
    if (dopValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((dopPrice * value) / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldDop(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainDop(value);
      } else {
        setInputFieldDop(0);
        setInputFieldMainDop("");
      }
    } else {
      setCheck(true);
      setInputFieldDop(0);
      setInputFieldMainDop("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1HandlerTomi = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceTomi - roundPriceTomi * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceTomi - roundPriceTomi * 0.07;
    } else {
      tokenPrice = roundPriceTomi;
    }
    if (tomiVlaue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((tomiPrice * value) / tokenPrice).toFixed(4);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFeildTomi(parseFloat(amount1)?.toFixed(2));
        setInputFeildMainTomi(value);
      } else {
        setInputFeildTomi(0);
        setInputFeildMainTomi("");
      }
    } else {
      setCheck(true);
      setInputFeildTomi(0);
      setInputFeildMainTomi("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1HandlerGems = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceGems - roundPriceGems * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceGems - roundPriceGems * 0.07;
    } else {
      tokenPrice = roundPriceGems;
    }
    if (gemsValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((gemsPrice * value) / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldGems(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainGems(value);
      } else {
        setInputFieldGems(0);
        setInputFieldMainGems("");
      }
    } else {
      setCheck(true);
      setInputFieldGems(0);
      setInputFieldMainGems("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const settingValue = (min) => {
    let minvalue = min * 0.99;
    minvalue = parseFloat(minvalue).toFixed(6);
    minvalue = Math.round(minvalue * 1e12) / 1e12;

    return (minvalue = web3.utils.toWei(minvalue.toString(), "ether"));
    // return 0
  };

  const deposite = async () => {
    console.log("round: ", currentRound);

    setLoarder(true);

    {
      let dummy = [];
      let eth = settingValue(inputFeild1);
      let usdt = settingValue(inputFeild3);
      let wbtc = settingValue(inputFeildWbtc);
      let usdc = settingValue(inputFieldUsdc);

      let pepe = settingValue(inputFieldPepe);
      let link = settingValue(inputFieldLink);
      let uni = settingValue(inputFieldUni);
      let dop = settingValue(inputFieldDop);
      let tomi = settingValue(inputFeildTomi);
      let gems = settingValue(inputFieldGems);

      setLoarder(true);
      dummy.push(eth);
      dummy.push(usdt);
      dummy.push(usdc);
      dummy.push(wbtc);
      dummy.push(pepe);
      dummy.push(link);
      dummy.push(uni);
      dummy.push(dop);
      dummy.push(tomi);
      dummy.push(gems);

      const claimableAmount = [];

      claimableAmount.push({ tokenSelected: "ETH", amount: inputFeild });

      claimableAmount.push({ tokenSelected: "USDT", amount: inputFeild2 });

      claimableAmount.push({
        tokenSelected: "USDC",
        amount: inputFieldMainUsdc,
      });

      claimableAmount.push({
        tokenSelected: "WBTC",
        amount: inputFeildMainWbtc,
      });

      claimableAmount.push({
        tokenSelected: "PEPE",
        amount: inputFieldMainPepe,
      });

      claimableAmount.push({
        tokenSelected: "LINK",
        amount: inputFieldMainLink,
      });

      claimableAmount.push({
        tokenSelected: "UNI",
        amount: inputFieldMainUni,
      });

      claimableAmount.push({
        tokenSelected: "DOP",
        amount: inputFieldMainDop,
      });

      claimableAmount.push({
        tokenSelected: "TOMI",
        amount: inputFeildMainTomi,
      });

      claimableAmount.push({
        tokenSelected: "GEMS",
        amount: inputFieldMainGems,
      });

      var config = {
        method: "post",
        url: `${Api_Url}/users/claims-tomi?privateSale=${project}`,
        data: {
          round: currentRound,

          claimableAmount: claimableAmount,
        },

        headers: {
          Authorization: "Bearer " + tok,
        },
      };

      console.log(
        "claimable amount: ",
        claimableAmount,
        "\n current round: ",
        currentRound
      );

      let v = await axios(config)
        .then(function (response) {
          return response.data.data;
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
          setLoarder(false);
        });

      if (v) {
        try {
          let a = await buyAgain1(currentRound, dummy, v, stakeIndex);
          if (a) {
            setLoarder(false);
            setShow4(false);
            setShow5(true);
            handleClose();
          }
        } catch (err) {
          toast.error(err.message);
          // console.log(err);
          console.log(err, "noor");
          setLoarder(false);
        }
      }
    }
  };
  const maxUsd = () => {
    // if (item[0]?._id?.round > 6) {

    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUsd - roundPriceUsd * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUsd - roundPriceUsd * 0.07;
    } else {
      tokenPrice = roundPriceUsd;
    }

    let value = usdtVlaue;
    let amount = parseFloat(value / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputField3(parseFloat(amount1)?.toFixed(2));
    setInputField2(value);
  };
  const ethMax = () => {
    // if (item[0]?._id?.round > 6) {

    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceEth - roundPriceEth * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceEth - roundPriceEth * 0.07;
    } else {
      tokenPrice = roundPriceEth;
    }

    let value = parseFloat(ethValue);
    let amount = parseFloat((ethPricevalue * value) / tokenPrice).toFixed(6);
    let amount1 = "";
    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputField1(parseFloat(amount1)?.toFixed(2));
    setInputField(value);
  };

  const wbtcMax = () => {
    let value = wbtcValue;
    let amount = parseFloat((btcPrice * value) / roundPriceWbtc).toFixed(6);
    let amount1 = "";
    if (roundPriceWbtc > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFeildWbtc(parseFloat(amount1)?.toFixed(2));
    setInputFeildMainWbtc(value);
  };

  const usdcMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUsdc - roundPriceUsdc * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUsdc - roundPriceUsdc * 0.07;
    } else {
      tokenPrice = roundPriceUsdc;
    }

    let value = usdcValue;
    let amount = parseFloat(value / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldUsdc(parseFloat(amount1)?.toFixed(2));
    setInputFieldMainUsdc(value);
  };

  const pepeMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.07;
    } else {
      tokenPrice = roundPricePepe;
    }
    let value = pepeValue;
    let amount = parseFloat((pepePrice * value) / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldPepe(parseFloat(amount1)?.toFixed(2));
    setInputFieldMainPepe(value);
  };

  const linkMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.07;
    } else {
      tokenPrice = roundPricePepe;
    }

    let value = linkValue;
    let amount = parseFloat((linkPrice * value) / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldLink(parseFloat(amount1)?.toFixed(2));
    setInputFieldMainLink(value);
  };

  const uniMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUni - roundPriceUni * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUni - roundPriceUni * 0.07;
    } else {
      tokenPrice = roundPriceUni;
    }

    let value = uniValue;
    let amount = parseFloat((uniPrice * value) / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldUni(parseFloat(amount1)?.toFixed(2));
    setInputFieldMainUni(value);
  };

  const dopMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceDop - roundPriceDop * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceDop - roundPriceDop * 0.07;
    } else {
      tokenPrice = roundPriceDop;
    }

    let value = dopValue;
    let amount = parseFloat((dopPrice * value) / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldDop(parseFloat(amount1)?.toFixed(2));
    setInputFieldMainDop(value);
  };

  const tomiMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceTomi - roundPriceTomi * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceTomi - roundPriceTomi * 0.07;
    } else {
      tokenPrice = roundPriceTomi;
    }

    let value = tomiVlaue;
    let amount = parseFloat((tomiPrice * value) / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFeildTomi(parseFloat(amount1)?.toFixed(2));
    setInputFeildMainTomi(value);
  };

  const gemsMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceGems - roundPriceGems * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceGems - roundPriceGems * 0.07;
    } else {
      tokenPrice = roundPriceGems;
    }

    let value = gemsValue;
    let amount = parseFloat((gemsPrice * value) / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldGems(parseFloat(amount1)?.toFixed(2));
    setInputFieldMainGems(value);
  };

  const discountEth = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.eth);
      if (a > 0) {
        setRountPriceEth(a);
      } else {
        setRountPriceEth(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const discountUsdt = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.busd);
      if (a > 0) {
        setRountPriceUsdt(a);
      } else {
        setRountPriceUsdt(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const discountWbtc = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.wbtc);

      if (a > 0) {
        setRountPriceWbtc(a);
      } else {
        setRountPriceWbtc(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };
  const discountUsdc = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.usdc);
      if (a > 0) {
        setRountPriceUsdc(a);
      } else {
        setRountPriceUsdc(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const discountPepe = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.pepe);
      if (a > 0) {
        setRountPricePepe(a);
      } else {
        setRountPricePepe(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };
  const discountLink = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.link);
      if (a > 0) {
        setRountPriceLink(a);
      } else {
        setRountPriceLink(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };
  const discountUni = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.uni);
      if (a > 0) {
        setRountPriceUni(a);
      } else {
        setRountPriceUni(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const discountDop = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.dop);
      if (a > 0) {
        setRountPriceDop(a);
      } else {
        setRountPriceDop(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const discountTomi = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.tomi);
      if (a > 0) {
        setRountPriceTomi(a);
      } else {
        setRountPriceTomi(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const discountGems = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.gems);
      if (a > 0) {
        setRountPriceGems(a);
      } else {
        setRountPriceGems(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  return (
    <>
      {loaderr && <Loader />}
      <tr>
        <td className="round">Round {item[0]?._id?.round}</td>
        <td>
          <div className="twice-text">
            {project == "DOP" ? (
              <p>
                ({ethValue} ETH + {usdtVlaue} USDT +{wbtcValue} WBTC +{" "}
                {usdcValue} USDC + {usdcValue} USDC)
              </p>
            ) : (
              <p>
                ({ethValue} ETH + {usdtVlaue} USDT + {dopValue} DOP +{" "}
                {wbtcValue} WBTC + {usdcValue} USDC + {pepeValue} PEPE +{" "}
                {linkValue} LINK + {uniValue} UNI + {usdcValue} USDC +{" "}
                {tomiVlaue} TOMI + {gemsValue} GEMS)
              </p>
            )}
          </div>
        </td>
        {(parseFloat(allowedStatus?.amountEth) > 0 ||
          parseFloat(allowedStatus?.amountUsd) > 0 ||
          parseFloat(
            allowedStatus?.amountWbtc ? allowedStatus?.amountWbtc : 0
          ) > 0 ||
          parseFloat(
            allowedStatus?.amountUsdc ? allowedStatus?.amountUsdc : 0
          ) > 0 ||
          parseFloat(
            allowedStatus?.amountPepe ? allowedStatus?.amountPepe : 0
          ) > 0 ||
          parseFloat(
            allowedStatus?.amountLink ? allowedStatus?.amountLink : 0
          ) > 0 ||
          parseFloat(allowedStatus?.amountUni ? allowedStatus?.amountUni : 0) >
            0 ||
          parseFloat(allowedStatus?.amountDop ? allowedStatus?.amountDop : 0) >
            0 ||
          parseFloat(
            allowedStatus?.amountTomi ? allowedStatus?.amountTomi : 0
          ) > 0 ||
          parseFloat(
            allowedStatus?.amountGems ? allowedStatus?.amountGems : 0
          ) > 0) &&
        claimStatus ? (
          <td className="yellow111">Approved</td>
        ) : statusState?.fundsClaimeds?.length > 0 ? (
          <td className="green111"> Claimed </td>
        ) : statusState?.investedWithClaimAmounts?.length > 0 ? (
          <td className="green111"> Rebought </td>
        ) : (
          <td className="red111">Pending</td>
        )}

        <td>
          <button
            className={
              (parseFloat(allowedStatus?.amountUsd) === 0 &&
                parseFloat(allowedStatus?.amountEth) === 0 &&
                parseFloat(allowedStatus?.amountWbtc) === 0 &&
                parseFloat(allowedStatus?.amountUsdc) === 0 &&
                parseFloat(allowedStatus?.amountPepe) === 0 &&
                parseFloat(allowedStatus?.amountLink) === 0 &&
                parseFloat(allowedStatus?.amountUni) === 0 &&
                parseFloat(allowedStatus?.amountDop) === 0 &&
                parseFloat(allowedStatus?.amountTomi) === 0 &&
                parseFloat(allowedStatus?.amountGems) === 0) ||
              !claimStatus ||
              statusState?.investedWithClaimAmounts?.length > 0
                ? "claimedbtn"
                : "claim-seperate"
            }
            // className="claimedbtn"
            disabled={
              (parseFloat(allowedStatus?.amountUsd) === 0 &&
                parseFloat(allowedStatus?.amountEth) === 0 &&
                parseFloat(allowedStatus?.amountWbtc) === 0 &&
                parseFloat(allowedStatus?.amountUsdc) === 0 &&
                parseFloat(allowedStatus?.amountPepe) === 0 &&
                parseFloat(allowedStatus?.amountLink) === 0 &&
                parseFloat(allowedStatus?.amountUni) === 0 &&
                parseFloat(allowedStatus?.amountDop) === 0 &&
                parseFloat(allowedStatus?.amountTomi) === 0 &&
                parseFloat(allowedStatus?.amountGems) === 0) ||
              !claimStatus ||
              statusState?.investedWithClaimAmounts?.length > 0
            }
            onClick={() => {
              if (project == "GEMS") {
                handleShowStaking();
              } else if (
                project == "INCENTIV" ||
                project == "LEIA" ||
                project == "OLYMPUS"
              ) {
                if (stakedAmount >= 18000) {
                  setShow(true);
                } else {
                  handleShowStaking();
                }
              } else {
                parseInt(item[0]?._id?.round) < 3 ? claim() : setShow(true);
              }
              // claim();;
            }}
          >
            Claim
          </button>
        </td>
      </tr>

      <Modal className="claimmodal" show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parentcontent">
            <img src="/assets/check.svg" alt="img" className="img-fluid" />
            <p>Amount claimed successfully</p>
          </div>
          <button className="ok" onClick={handleClose1}>
            Okay
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        className="claimmodal set-custom-width-claim"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalcard">
            <div className="cardparent">
              <h3>Total Amount</h3>
              <h5>
                $
                {parseFloat(
                  parseFloat(parseFloat(ethValue) * usdPrice) +
                    parseFloat(usdtVlaue) +
                    parseFloat(usdcValue) +
                    parseFloat(wbtcValue) * btcPrice +
                    parseFloat(dopValue) * dopPrice +
                    parseFloat(pepeValue) * pepePrice +
                    parseFloat(linkValue) * linkPrice +
                    parseFloat(uniValue) * uniPrice +
                    parseFloat(tomiVlaue) * tomiPrice +
                    parseFloat(gemsValue) * gemsPrice
                )
                  .toFixed(2)
                  ?.toLocaleString()}
              </h5>
              <h6>
                {ethValue} ETH + {usdtVlaue} USDT + {usdcValue} USDC +{" "}
                {dopValue} DOP + {wbtcValue} WBTC + {pepeValue} PEPE +{" "}
                {linkValue} LINK + {uniValue} UNI + {tomiVlaue} TOMI +{" "}
                {gemsValue} GEMS
              </h6>
              {/* <a onClick={() => {
                handleClose();
                handleShow3();
              }} className="btn-detail">DETAILS <svg xmlns="http://www.w3.org/2000/svg" width="9" height="17" viewBox="0 0 9 17" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.44417 9.0035L3.43713 13.0105L2.43555 12.009L5.9418 8.50271L2.43555 4.99646L3.43713 3.99487L7.44417 8.00191C7.57696 8.13475 7.65156 8.31488 7.65156 8.50271C7.65156 8.69053 7.57696 8.87067 7.44417 9.0035Z" fill="black" />
              </svg></a> */}
            </div>
          </div>
          <div className="twice-content">
            <div className="inner-main">
              <h2 className="claimall">Claim All Now</h2>
              <p className="para">
                Your claim funds amount will be transferred to your wallet
                instantly.
              </p>
              <button
                className="claimbtn"
                onClick={() => {
                  claim();
                }}
              >
                Claim ALL NOW
              </button>
            </div>
            <div className="inner-main">
              {project == "INCENTIV" ||
              project == "LEIA" ||
              project == "OLYMPUS" ? (
                <>
                  <h2 className="claimall">Buy ${project} with Claims</h2>
                  <p className="para">
                    Buy ${project} with all or partial amount of your available
                    claims. This purchase will not result in any commissions for
                    you.
                  </p>
                  <button
                    className="claimbtn"
                    onClick={() => {
                      if (!account) {
                        toast.info("Connect Your Wallet");
                        return;
                      }
                      if (
                        account.toLowerCase() !==
                        user?.walletAddress?.toLowerCase()
                      ) {
                        toast.error("Wrong Wallet For Claim");
                        return;
                      }

                      setLoarder(true);
                      setCurrentRound(item[0]?._id?.round);

                      handleClose1();
                      setShow4(true);

                      discountUsdt(item[0]?._id?.round);
                      discountEth(item[0]?._id?.round);
                      discountWbtc(item[0]?._id?.round);
                      discountUsdc(item[0]?._id?.round);

                      discountPepe(item[0]?._id?.round);
                      discountLink(item[0]?._id?.round);
                      discountUni(item[0]?._id?.round);
                      discountDop(item[0]?._id?.round);
                      discountTomi(item[0]?._id?.round);
                      discountGems(item[0]?._id?.round);

                      setTimeout(() => {
                        setLoarder(false);
                      }, "3000");
                    }}
                  >
                    Buy ${project} WITH CLAIMS
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="claimmodal set-custom-width-claim"
        show={show3}
        onHide={handleClose3}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            {" "}
            <a
              onClick={() => {
                handleClose3();
                handleShow();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M15 8.25H5.8725L10.065 4.0575L9 3L3 9L9 15L10.0575 13.9425L5.8725 9.75H15V8.25Z"
                  fill="black"
                />
              </svg>
            </a>{" "}
            Claim
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="claim-detail-table">
            <h6 className="main-heading">Claim Details</h6>
            <div className="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Address</th>
                    <th>payment</th>
                    <th>70% earning</th>
                    <th>sTATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="copy-text">
                        <p>0x97E6...79A</p>
                        <a href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="20"
                            viewBox="0 0 19 20"
                            fill="none"
                          >
                            <path
                              d="M6.87023 7.72593L6.89284 5.6262C6.90189 4.78617 6.90641 4.36584 7.07335 4.04675C7.22019 3.76607 7.45195 3.53925 7.73572 3.39849C8.05833 3.23846 8.47866 3.24299 9.31869 3.25203L13.5184 3.29726C14.3585 3.3063 14.7783 3.31082 15.0974 3.47776C15.378 3.6246 15.6053 3.85636 15.746 4.14014C15.9061 4.46275 15.9015 4.88276 15.8925 5.7228L15.8473 9.92257C15.8382 10.7626 15.8337 11.1826 15.6668 11.5017C15.5199 11.7824 15.2875 12.0097 15.0038 12.1504C14.6815 12.3103 14.2621 12.3058 13.4237 12.2968L11.3215 12.2741M6.87023 7.72593L4.7705 7.70332C3.93047 7.69427 3.51014 7.68975 3.18753 7.84977C2.90376 7.99053 2.67199 8.21736 2.52515 8.49803C2.35822 8.81712 2.35369 9.23745 2.34465 10.0775L2.29942 14.2772C2.29038 15.1173 2.28586 15.5371 2.44588 15.8597C2.58664 16.1434 2.81346 16.3756 3.09414 16.5224C3.41292 16.6892 3.83274 16.6937 4.67112 16.7028L8.87586 16.748C9.71425 16.7571 10.1335 16.7616 10.4558 16.6017C10.7396 16.461 10.9717 16.2335 11.1186 15.9528C11.2853 15.6341 11.2898 15.2147 11.2989 14.3763L11.3215 12.2741M6.87023 7.72593L8.97026 7.74854C9.81029 7.75759 10.2301 7.76211 10.5492 7.92904C10.8298 8.07589 11.0571 8.30765 11.1978 8.59142C11.3577 8.91372 11.3532 9.33355 11.3442 10.172L11.3215 12.2741"
                              stroke="#C7C7C7"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="twice-text">
                        <h6>$1000</h6>
                        <p>(0.5 ETH)</p>
                      </div>
                    </td>
                    <td>
                      <div className="twice-text">
                        <h6 className="orange-text">$700</h6>
                        <p>(0.5 ETH)</p>
                      </div>
                    </td>
                    <td>
                      <p className="insurance-text orange-text">
                        Claimed <br /> Insurance
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="claimmodal set-custom-width-claim"
        show={show4}
        onHide={handleClose4}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Buy ${project}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="doppara">
            You will be able to purchase ${project} with full or partial amount
            of your available claims. In case of partial amount, the remaining
            available claims will be sent to your wallet in the same transaction
          </p>
          <div className="dopparent">
            {ethValue > 0 ? (
              <div className="twice-field mb-4">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={inputField1Handler}
                      value={inputFeild}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter ETH amount"
                    />
                    <label>Amount ETH</label>
                  </div>
                  <a onClick={ethMax}>Max</a>
                  <h6 className="specific-value-text">
                    ETH Available for Claim:{" "}
                    <span>
                      {parseFloat(ethValue - inputFeild).toFixed(5)} ETH
                    </span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {usdtVlaue > 0 ? (
              <div className="twice-field mb-4">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={inputField1Handler1}
                      value={inputFeild2}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter USDT amount"
                    />
                    <label>Amount USDT</label>
                  </div>
                  <a onClick={maxUsd}>Max</a>
                  <h6 className="specific-value-text">
                    USDT Available for Claim:{" "}
                    <span>{usdtVlaue - inputFeild2} USDT</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {wbtcValue > 0 ? (
              <div className="twice-field ">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={inputField1Handler3}
                      value={inputFeildMainWbtc}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter WBTC amount"
                    />
                    <label>Amount WBTC</label>
                  </div>
                  <a onClick={wbtcMax}>Max</a>
                  <h6 className="specific-value-text">
                    WBTC Available for Claim:{" "}
                    <span>{wbtcValue - inputFeildMainWbtc} WBTC</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {usdcValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={inputField1Handler4}
                      value={inputFieldMainUsdc}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter USDC amount"
                    />
                    <label>Amount USDC</label>
                  </div>
                  <a onClick={usdcMax}>Max</a>
                  <h6 className="specific-value-text">
                    USDC Available for Claim:{" "}
                    <span>{usdcValue - inputFieldMainUsdc} USDC</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {dopValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={inputField1HandlerDop}
                      value={inputFieldMainDop}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter DOP amount"
                    />
                    <label>Amount DOP</label>
                  </div>
                  <a onClick={dopMax}>Max</a>
                  <h6 className="specific-value-text">
                    DOP Available for Claim:{" "}
                    <span>{dopValue - inputFieldMainDop} DOP</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {pepeValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={inputField1HandlerPepe}
                      value={inputFieldMainPepe}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter PEPE amount"
                    />
                    <label>Amount PEPE</label>
                  </div>
                  <a onClick={pepeMax}>Max</a>
                  <h6 className="specific-value-text">
                    PEPE Available for Claim:{" "}
                    <span>{pepeValue - inputFieldMainPepe} PEPE</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}{" "}
            {linkValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={inputField1HandlerLink}
                      value={inputFieldMainLink}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter LINK amount"
                    />
                    <label>Amount LINK</label>
                  </div>
                  <a onClick={linkMax}>Max</a>
                  <h6 className="specific-value-text">
                    LINK Available for Claim:{" "}
                    <span>{linkValue - inputFieldMainLink} LINK</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}{" "}
            {uniValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={inputField1HandlerUni}
                      value={inputFieldMainUni}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter UNI amount"
                    />
                    <label>Amount UNI</label>
                  </div>
                  <a onClick={uniMax}>Max</a>
                  <h6 className="specific-value-text">
                    UNI Available for Claim:{" "}
                    <span>{uniValue - inputFieldMainUni} UNI</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {tomiVlaue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={inputField1HandlerTomi}
                      value={inputFeildMainTomi}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter TOMI amount"
                    />
                    <label>Amount TOMI</label>
                  </div>
                  <a onClick={tomiMax}>Max</a>
                  <h6 className="specific-value-text">
                    TOMI Available for Claim:{" "}
                    <span>{tomiVlaue - inputFeildMainTomi} TOMI</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {gemsValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={inputField1HandlerGems}
                      value={inputFieldMainGems}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter GEMS amount"
                    />
                    <label>Amount GEMS</label>
                  </div>
                  <a onClick={gemsMax}>Max</a>
                  <h6 className="specific-value-text">
                    GEMS Available for Claim:{" "}
                    <span>{gemsValue - inputFieldMainGems} GEMS</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {check && (
              <div className="">
                <p className="error-msg">
                  Amount must be smaller than balance amount
                </p>
              </div>
            )}
          </div>
          <div className="twice-box">
            <div className="parentdoptext">
              <h6>Balance</h6>

              <p>
                {ethValue > 0 && `${ethValue} ETH`}
                {ethValue > 0 && usdtVlaue > 0 && ` + `}
                {usdtVlaue > 0 && `${usdtVlaue} USDT`}
                {(ethValue > 0 || usdtVlaue > 0) && usdcValue > 0 && ` + `}
                {usdcValue > 0 && `${usdcValue} USDC`}
                {(ethValue > 0 || usdtVlaue > 0 || usdcValue > 0) &&
                  pepeValue > 0 &&
                  ` + `}
                {pepeValue > 0 && `${pepeValue} PEPE`}
                {(ethValue > 0 ||
                  usdtVlaue > 0 ||
                  usdcValue > 0 ||
                  pepeValue > 0) &&
                  wbtcValue > 0 &&
                  ` + `}
                {wbtcValue > 0 && `${wbtcValue} WBTC`}
                {(ethValue > 0 ||
                  usdtVlaue > 0 ||
                  usdcValue > 0 ||
                  pepeValue > 0 ||
                  wbtcValue > 0) &&
                  linkValue > 0 &&
                  ` + `}
                {linkValue > 0 && `${linkValue} LINK`}
                {(ethValue > 0 ||
                  usdtVlaue > 0 ||
                  usdcValue > 0 ||
                  pepeValue > 0 ||
                  wbtcValue > 0 ||
                  linkValue > 0) &&
                  uniValue > 0 &&
                  ` + `}
                {uniValue > 0 && `${uniValue} UNI`}
                {(ethValue > 0 ||
                  usdtVlaue > 0 ||
                  usdcValue > 0 ||
                  pepeValue > 0 ||
                  wbtcValue > 0 ||
                  linkValue > 0 ||
                  uniValue > 0) &&
                  dopValue > 0 &&
                  ` + `}
                {dopValue > 0 && `${dopValue} DOP`}
                {(ethValue > 0 ||
                  usdtVlaue > 0 ||
                  usdcValue > 0 ||
                  pepeValue > 0 ||
                  wbtcValue > 0 ||
                  linkValue > 0 ||
                  uniValue > 0 ||
                  dopValue > 0) &&
                  tomiVlaue > 0 &&
                  ` + `}
                {tomiVlaue > 0 && `${tomiVlaue} TOMI`}
                {(ethValue > 0 ||
                  usdtVlaue > 0 ||
                  usdcValue > 0 ||
                  pepeValue > 0 ||
                  wbtcValue > 0 ||
                  linkValue > 0 ||
                  uniValue > 0 ||
                  dopValue > 0 ||
                  tomiVlaue > 0) &&
                  gemsValue > 0 &&
                  ` + `}
                {gemsValue > 0 && `${gemsValue} GEMS`}
              </p>
            </div>
            <div className="parentdoptext">
              <h6>{project} Price</h6>
              {stakedAmount >= 18000 && currentRound == 1 ? (
                <p>{roundPrice > 0 ? roundPrice - roundPrice * 0.2 : "0.00"}</p>
              ) : stakedAmount >= 18000 && currentRound > 1 ? (
                <p>
                  {roundPrice > 0 ? roundPrice - roundPrice * 0.07 : "0.00"}
                </p>
              ) : (
                <p>{roundPrice > 0 ? roundPrice : "0.00"}</p>
              )}
            </div>
            <div className="parentdoptext">
              <h6>Total {project}</h6>
              <p>
                {" "}
                {inputFeild1 > 0 ||
                inputFeild3 > 0 ||
                inputFieldUsdc > 0 ||
                inputFeildWbtc > 0 ||
                inputFieldPepe > 0 ||
                inputFieldLink > 0 ||
                inputFieldUni > 0 ||
                inputFieldDop > 0 ||
                inputFeildTomi > 0 ||
                inputFieldGems > 0
                  ? parseFloat(inputFeild1) +
                    parseFloat(inputFeild3) +
                    parseFloat(inputFeildWbtc) +
                    parseFloat(inputFieldUsdc) +
                    parseFloat(inputFieldPepe) +
                    parseFloat(inputFieldLink) +
                    parseFloat(inputFieldUni) +
                    parseFloat(inputFieldDop) +
                    parseFloat(inputFeildTomi) +
                    parseFloat(inputFieldGems)
                  : "0.00"}
              </p>
            </div>
          </div>
          <div className="twicebtns">
            <button
              className="back"
              onClick={() => {
                handleClose4();
              }}
            >
              Go Back
            </button>
            <button
              className="buy"
              onClick={() => {
                deposite();
              }}
            >
              Buy {project}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="claimmodal" show={show5} onHide={handleClose5} centered>
        <Modal.Header closeButton>
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parentcontent">
            <img src="/assets/check.svg" alt="img" className="img-fluid" />
            <p className="purchasedpara">You have successfully purchased</p>
            <p className="forspan">
              <span>
                {parseFloat(inputFeild1) +
                  parseFloat(inputFeild3) +
                  parseFloat(inputFieldUsdc) +
                  parseFloat(inputFeildWbtc) +
                  parseFloat(inputFieldPepe) +
                  parseFloat(inputFieldLink) +
                  parseFloat(inputFieldUni) +
                  parseFloat(inputFieldDop) +
                  parseFloat(inputFeildTomi) +
                  parseFloat(inputFieldGems)}{" "}
                {project}{" "}
              </span>
              for{" "}
              <span>
                {inputFeild} ETH , {inputFeild2} USDT, {inputFieldMainUsdc}{" "}
                USDC, {inputFieldMainDop} DOP, {inputFeildMainWbtc} WBTC,{" "}
                {inputFieldMainPepe} PEPE, {inputFieldMainLink} LINK,{" "}
                {inputFieldMainUni} UNI, {inputFeildMainTomi} TOMI,{" "}
                {inputFieldMainGems} GEMS
              </span>
            </p>
          </div>
          <button className="ok" onClick={handleClose5}>
            Okay
          </button>
        </Modal.Body>
      </Modal>
      <Modal
        className="claimmodal"
        show={showStaking}
        onHide={handleCloseStaking}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parentcontent">
            <img src="/assets/Earningss.png" alt="img" className="img-fluid" />
            {/* <p>Amount claimed successfully</p> */}
          </div>
          <div className="onlymodbtnns">
            <button
              className="  claimbtn m-0"
              onClick={() => {
                handleCloseStaking();
                setShow(true);
              }}
            >
              proceed to claim
            </button>
            <button className=" ok " onClick={() => history.push("/staking")}>
              Go Premium
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TableRow;
