import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { useWeb3React } from "@web3-react/core";
import {
  getClaimNewLatest,
  getGemsClaimsContract,
  getIncentivClaimsContract,
  getLEIAClaimsContract,
  getOlympusClaimsContract,
} from "../../utils/contractHelpers";

const BuyAgain1 = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = environment.claimNewLatest;
  const contract = getClaimNewLatest(tokenAddress, web3);
  const gemsAddress = environment.GemsClaim;
  const IncentivAddress = environment.IncentivClaim;
  const LEIAAddress = environment.LEIAClaim;
  const OlympusAddress = environment.OlympusClaim;

  const gemsClaimsContract = getGemsClaimsContract(gemsAddress, web3);
  const incentivClaimsContract = getIncentivClaimsContract(
    IncentivAddress,
    web3
  );

  const LEIAClaimsContract = getLEIAClaimsContract(LEIAAddress, web3);
  const OlympusClaimsContract = getOlympusClaimsContract(OlympusAddress, web3);

  const project = localStorage.getItem("project");

  const buyAgain1 = useCallback(
    async (round, min, api, indexes) => {
      if (project == "INCENTIV") {
        try {
          console.log(
            "deadline: ",
            api?.deadline,
            "\n amounts: ",
            api?.amounts,
            "\n indexes: ",
            indexes,
            "\n minimum amounts: ",
            min,
            "prices: ",
            api?.prices,
            "\n tokens:",
            api?.tokens,
            "\n round: ",
            "5",
            "\nfactors: ",
            api?.factor,
            "\n v: ",
            api.v,
            "\n r: ",
            api.r,
            "\ns: ",
            api.s
          );
          let gasPrice = await web3.eth.getGasPrice();
          gasPrice = parseInt(gasPrice);
          const gas = await incentivClaimsContract.methods
            .purchaseWithClaim(
              api?.deadline?.toString(),
              api?.amounts,
              indexes,
              min,
              api?.prices,
              api?.tokens,
              api?.round?.toString(),
              api?.factor,
              api.v,
              api.r,
              api.s
            )
            .estimateGas({
              from: account,
            });

          const details = await incentivClaimsContract.methods
            .purchaseWithClaim(
              api?.deadline?.toString(),
              api?.amounts,
              indexes,
              min,
              api?.prices,
              api?.tokens,
              api?.round?.toString(),
              api?.factor,
              api.v,
              api.r,
              api.s
            )
            .send({
              from: account,
              gas: gas,
              gasPrice,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "LEIA") {
        try {
          console.log(
            "deadline: ",
            api?.deadline,
            "\n amounts: ",
            api?.amounts,
            "\n indexes: ",
            indexes,
            "\n minimum amounts: ",
            min,
            "prices: ",
            api?.prices,
            "\n tokens:",
            api?.tokens,
            "\n round: ",
            api?.round?.toString(),
            "\nfactors: ",
            api?.factor,
            "\n v: ",
            api.v,
            "\n r: ",
            api.r,
            "\ns: ",
            api.s
          );
          // let gasPrice = await web3.eth.getGasPrice();
          // gasPrice = parseInt(gasPrice);
          // const gas = await LEIAClaimsContract.methods
          //   .purchaseWithClaim(
          //     api?.deadline?.toString(),
          //     api?.amounts,
          //     indexes,
          //     min,
          //     api?.prices,
          //     api?.tokens,
          //     api?.round?.toString(),
          //     api?.factor,
          //     api.v,
          //     api.r,
          //     api.s
          //   )
          //   .estimateGas({
          //     from: account,
          //   });
          const details = await LEIAClaimsContract.methods
            .purchaseWithClaim(
              api?.deadline?.toString(),
              api?.amounts,
              indexes,
              min,
              api?.prices,
              api?.tokens,
              api?.round?.toString(),
              api?.factor,
              api.v,
              api.r,
              api.s
            )
            .send({
              from: account,
              // gas: gas,
              // gasPrice,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "OLYMPUS") {
        try {
          console.log(
            "deadline: ",
            api?.deadline,
            "\n amounts: ",
            api?.amounts,
            "\n indexes: ",
            indexes,
            "\n minimum amounts: ",
            min,
            "prices: ",
            api?.prices,
            "\n tokens:",
            api?.tokens,
            "\n round: ",
            api?.round?.toString(),
            "\nfactors: ",
            api?.factor,
            "\n v: ",
            api.v,
            "\n r: ",
            api.r,
            "\ns: ",
            api.s
          );
          let gasPrice = await web3.eth.getGasPrice();
          gasPrice = parseInt(gasPrice);
          const gas = await OlympusClaimsContract.methods
            .purchaseWithClaim(
              api?.deadline?.toString(),
              api?.amounts,
              indexes,
              min,
              api?.prices,
              api?.tokens,
              api?.round?.toString(),
              api?.factor,
              api.v,
              api.r,
              api.s
            )
            .estimateGas({
              from: account,
            });
          const details = await OlympusClaimsContract.methods
            .purchaseWithClaim(
              api?.deadline?.toString(),
              api?.amounts,
              indexes,
              min,
              api?.prices,
              api?.tokens,
              api?.round?.toString(),
              api?.factor,
              api.v,
              api.r,
              api.s
            )
            .send({
              from: account,
              gas: gas,
              gasPrice,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "GEMS") {
        try {
          // let gasPrice = await web3.eth.getGasPrice();
          // gasPrice = parseInt(gasPrice) + 3000000000;
          // const gas = await gemsClaimsContract.methods
          //   .purchaseWithClaim(
          //     api?.round,
          //     api?.deadline,
          //     api?.factors,
          //     api?.prices,
          //     api?.tokens,
          //     api?.amounts,
          //     min,
          //     api.v,
          //     api.r,
          //     api.s
          //   )
          //   .estimateGas({
          //     from: account,
          //   });
          const details = await gemsClaimsContract.methods
            .purchaseWithClaim(
              api?.round,
              api?.deadline,
              api?.factors,
              api?.prices,
              api?.tokens,
              api?.amounts,
              min,
              api.v,
              api.r,
              api.s
            )
            .send({
              from: account,
              // gas: gas,
              // gasPrice,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else {
        try {
          let gasPrice = await web3.eth.getGasPrice();
          gasPrice = parseInt(gasPrice) + 3000000000;
          const gas = await contract.methods
            .purchaseWithClaim(
              api?.round,
              api?.deadline,
              api?.factors,
              api?.prices,
              api?.tokens,
              api?.amounts,
              min,
              api.v,
              api.r,
              api.s
            )
            .estimateGas({
              from: account,
            });
          const details = await contract.methods
            .purchaseWithClaim(
              api?.round,
              api?.deadline,
              api?.factors,
              api?.prices,
              api?.tokens,
              api?.amounts,
              min,
              api.v,
              api.r,
              api.s
            )
            .send({
              from: account,
              gas: gas,
              gasPrice,
            });
          return details;
        } catch (error) {
          throw error;
        }
      }
    },
    [contract, account, web3]
  );
  return { buyAgain1: buyAgain1 };
};
export default BuyAgain1;
